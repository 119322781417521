.wrapper-nav {
  position: relative;
  height: 100%;
}

.list-nav {
  display: flex;
}

.icon-arrow {
  position: absolute;
  display: inline-block;

  top: 53%;
  right: -14px;
  width: 12px;
  height: 12px;

  transform: translate(0, -50%);
  transition: all 250ms linear;
}

.item-nav {
  position: relative;
  height: 100%;

  display: flex;

  &:not(:last-child) {
    margin-right: 38px;
  }

  &:nth-child(1) {
    margin-right: 24px;

    .icon-arrow {
      display: none;
    }
  }
  &:nth-child(4) {
    margin-right: 24px;

    .icon-arrow {
      display: none;
    }
  }
  &:nth-child(6) {
    margin-right: 24px;

    .icon-arrow {
      display: none;
    }
  }
  &:nth-child(7) {
    margin-right: 24px;

    .icon-arrow {
      display: none;
    }
  }
}

.link-nav {
  color: $header-color;
  display: block;

  padding-top: 36px;
  padding-bottom: 36px;

  font-weight: $medium;
  font-size: 14px;
  line-height: 24px;
  transition: all 250ms linear;

  &.active {
    position: relative;

    color: $active-color;
    transition: all 250ms linear;
    &::after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $active-color;
    }
  }
  &.activeDropdown {
    color: $active-color;
    fill: $active-color;
    & .icon-arrow {
      transform: rotate(180deg);
      top: 44%;
      transition: all 250ms linear;
    }
    transition: all 250ms linear;
  }
}
