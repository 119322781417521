//fonts
$bolt: 700;
$medium: 500;
$regular: 400;

// colors
$active-color: #0880ae;
$rating-color: #f26532;
$header-color: #252525;
$text-color: #434343;
$text-secondary-color: #727272;
$bg-base-main-color: #ffffff;
$bg-secondary-color: #f5f5f5;

// brackpoints
$mobile: 480px;
$tablet: 640px;
$desktop-mini: 1024px;
$desktop: 1280px;
$desktop-fullHD: 1480px;
