.hero {
  padding-top: 56px;
  padding-bottom: 80px;
}

.wrapper-hero {
  display: flex;
  justify-content: center;
  align-items: center;

  & .wrapper-content {
    display: flex;

    background-color: $bg-secondary-color;

    margin-right: 24px;
  }
}

.wrapper-img {
  width: 424px;
  height: 432px;

  margin-right: 24px;
}

.wrapper-info {
  max-width: 400px;

  padding: 32px 24px 32px 0;

  & > div {
    display: flex;
  }
}

.img-hero {
  display: block;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
}

.header-hero {
  color: $active-color;

  margin-bottom: 8px;

  font-weight: $bolt;
  font-size: 14px;
  line-height: 24px;
}
.subHeader-hero {
  color: $header-color;

  margin-bottom: 8px;

  font-weight: $medium;
  font-size: 22px;
  line-height: 40px;
}
.text-hero {
  color: $text-color;

  margin-bottom: 36px;

  font-size: 14px;
  line-height: 28px;
}
.wrapper-data-hero {
  display: flex;
  align-items: center;

  margin-right: 32px;
}
.date-info-hero {
  color: $text-secondary-color;

  margin-right: 8px;

  font-size: 12px;
  line-height: 16px;
}
.user-info-hero {
  color: $active-color;

  font-size: 12px;
  line-height: 16px;
}
.wrapper-time-hero {
  display: flex;

  align-items: center;
}
.icon-time-hero {
  fill: $header-color;

  margin-right: 4px;

  width: 12px;
  height: 12px;
}
.text-time-hero {
  color: $text-secondary-color;

  font-size: 12px;
  line-height: 16px;
}
