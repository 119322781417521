@media screen and (min-width: $tablet) and (max-width: ($desktop-mini - 1)) {
  .icon-arrow {
    position: absolute;
    display: inline-block;

    top: 50%;
    right: 38%;

    width: 12px;
    height: 12px;

    transform: translate(-50%, -50%);
    transition: all 250ms linear;
  }
  .list-nav {
    flex-direction: column;
    margin-top: 100px;
    text-align: center;
  }

  .link-nav {
    display: block;

    width: 100%;

    padding-top: 15px;
    padding-bottom: 15px;

    font-size: 18px;

    &:not(:last-child) {
      margin-right: 0;
    }
    &.active {
      color: $active-color;
      transition: all 250ms linear;
      position: relative;

      color: $active-color;
      transition: all 250ms linear;
      &::after {
        content: " ";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $active-color;
      }
    }
    &.activeDropdown {
      color: $active-color;
      fill: $active-color;
      & .icon-arrow {
        transform: rotate(180deg);
        top: 35%;
        transition: all 250ms linear;
      }
      transition: all 250ms linear;
    }
  }

  .item-nav {
    position: relative;
    height: 100%;

    display: flex;

    border-bottom: 1px solid $active-color;

    &:not(:last-child) {
      margin-right: 0;
    }

    &:nth-child(1) {
      margin-right: 0;

      .icon-arrow {
        display: none;
      }
    }
    &:nth-child(4) {
      margin-right: 0;

      .icon-arrow {
        display: none;
      }
    }
    &:nth-child(6) {
      margin-right: 0;

      .icon-arrow {
        display: none;
      }
    }
    &:nth-child(7) {
      margin-right: 0;

      .icon-arrow {
        display: none;
      }
    }
  }
}
