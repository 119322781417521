.wrapper-contact-header {
}
.contact-header-text {
  color: $text-secondary-color;

  margin-bottom: 8px;

  font-size: 12px;
  line-height: 16px;
}
.contact-header-link {
  color: $header-color;

  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
}
