.wrapper-logo {
  margin-right: 40px;
}
.link-logo {
  display: block;
}
.icon-logo {
  display: block;
  width: 140px;
  height: 56px;
}
