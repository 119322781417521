@media screen and (min-width: $tablet) and (max-width: ($desktop-mini - 1)) {
  .wrapper-header {
    height: 80px;
  }
  .wrapper-nav {
    position: absolute;
    top: 0;
    left: 0;
    display: block;

    background-color: $bg-secondary-color;

    width: 100%;
    height: 100vh;

    transform: translateX(-100%);

    transition: transform 250ms linear;
  }

  .block-scroll {
    overflow: hidden;
  }

  .active-mob-menu {
    transform: translateX(0);
    transition: transform 250ms linear;
  }

  .wrapper-burger {
    display: block;
    position: relative;

    background-color: transparent;

    width: 32px;
    height: 30px;

    border: 2px solid $active-color;
    border-radius: 5px;

    z-index: 5;
  }
  .burger {
    position: absolute;
    top: 50%;
    left: 50%;

    background-color: $active-color;

    width: 25px;
    height: 2px;

    border-radius: 10px;

    transform: translate(-50%, -50%);
    transition: background-color 250ms linear;
    &::after {
      content: " ";
      display: block;
      position: absolute;
      top: -7px;
      left: 0;

      background-color: $active-color;

      width: 25px;
      height: 2px;

      border-radius: 10px;
      border-radius: 10px;
      transition: top 250ms linear, transform 250ms linear;
    }
    &::before {
      content: " ";
      display: block;
      position: absolute;
      top: 7px;
      left: 0;

      background-color: $active-color;

      width: 25px;
      height: 2px;

      border-radius: 10px;
      transition: top 250ms linear, transform 250ms linear;
    }
    &.close {
      background-color: transparent;

      transition: background-color 250ms linear;

      &::after {
        top: 0px;

        transform: rotate(45deg);

        transition: top 250ms linear, transform 250ms linear;
      }
      &::before {
        top: 0px;

        transform: rotate(-45deg);

        transition: top 250ms linear, transform 250ms linear;
      }
    }
  }
}
