body {
  font-family: "Poppins", sans-serif;
  font-weight: $regular;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
  padding: 0;
}

.container {
  padding-right: 24px;
  padding-left: 24px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: $mobile) {
    width: calc($mobile - 20px);
  }

  @media screen and (min-width: $tablet) {
    // padding-left: 30px;
    // padding-right: 30px;
    width: calc($tablet - 48px);
  }

  @media screen and (min-width: $desktop-mini) {
    width: calc($desktop-mini - 48px);
  }

  @media screen and (min-width: $desktop) {
    width: calc($desktop - 48px);
  }

  @media screen and (min-width: $desktop-fullHD) {
    width: calc($desktop-fullHD - 48px);
  }
}
