.wrapper-signIn {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  text-align: center;
}
.form-signIn {
  display: flex;
  flex-direction: column;

  background-color: rgb(117, 117, 117);

  padding: 25px;

  margin-bottom: 30px;
}
.input-signIn {
  min-width: 250px;

  margin-bottom: 30px;

  padding: 5px;
}
.btn-signIn {
  padding: 10px;

  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;

  cursor: pointer;
}
.go-auth {
  color: rgb(0, 0, 0);

  font-size: 24px;
  font-weight: 700;
}
.error {
  color: red;
}
