.testimonials {
  background-color: $bg-secondary-color;

  padding-top: 80px;
  padding-bottom: 80px;

  text-align: center;
}

.header-testimonials {
  color: $header-color;

  margin-bottom: 20px;

  font-weight: $medium;
  font-size: 36px;
  line-height: 56px;
}
.list-comment {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 268px);

  margin-bottom: 60px;

  text-align: left;
}
.item-comment {
  margin: 20px 12px 20px 12px;
}
.comment {
  background-image: url("data:image/svg+xml,%3Csvg width='244' height='310' viewBox='0 0 244 310' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='path-1-inside-1_1801_374' fill='white'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M244 0H0V292V310L18 292H244V0Z'/%3E%3C/mask%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M244 0H0V292V310L18 292H244V0Z' fill='white'/%3E%3Cpath d='M0 0V-1H-1V0H0ZM244 0H245V-1H244V0ZM0 310H-1V312.414L0.707107 310.707L0 310ZM18 292V291H17.5858L17.2929 291.293L18 292ZM244 292V293H245V292H244ZM0 1H244V-1H0V1ZM1 292V0H-1V292H1ZM1 310V292H-1V310H1ZM17.2929 291.293L-0.707107 309.293L0.707107 310.707L18.7071 292.707L17.2929 291.293ZM244 291H18V293H244V291ZM243 0V292H245V0H243Z' fill='%23C1C7D0' mask='url(%23path-1-inside-1_1801_374)'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  width: 196px;
  height: 290px;

  padding: 20px 24px 0 24px;
}
.user-name-comment {
  color: $header-color;

  margin-bottom: 2px;

  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
}
.user-work {
  color: $header-color;

  margin-bottom: 10px;

  font-weight: $medium;
  font-size: 12px;
  line-height: 16px;
}
.user-comment {
  color: $text-color;

  font-style: italic;
  font-size: 14px;
  line-height: 28px;
}
.wrapper-user-rating {
  display: flex;

  align-items: center;

  padding-left: 4px;
}
.icon-user {
  margin-right: 12px;

  border-radius: 50%;
}
.user-name-rating {
  margin-bottom: 4px;

  font-weight: $medium;
  font-size: 14px;
  line-height: 21px;
}

@media screen and (min-width: $desktop-fullHD) {
  .list-comment {
    grid-template-columns: repeat(5, 268px);
  }
}
